<template>

  <div class="map-detail" @click="routeToHotelDetail" :class="{ inMap, mapView }">

    <div class="hotel-image">
      <img :src="hotel.image || hotel.images[0]" alt="">
    </div>

    <div class="hotel-info">
      <div class="stars">
        <img v-for="(star, i) in hotel.stars" class="star" :key="`stars-hotel-card-small-${hotel.id}-${i}`" :src="require('@/assets/ic_hotel_card_star_1.svg')" alt="">
      </div>
      <h2 class="hotel-name">{{hotel.name}}</h2>
      <p class="hotel-price" v-if="dataHotelsIsReady">{{hotel.average_day_price.toFixed(2)}}€</p>
      <p class="price-per-night" v-if="dataHotelsIsReady">{{ $t('pricePerNight') }}</p>
    </div>

  </div>

</template>

<script>
import { calculateHotelPriceHelper } from '../../helpers/calculateHotelPrice'
import { mapGetters } from 'vuex'

export default {
  name: 'HotelCardSmall',
  props: {
    hotel: Object,
    inMap: Boolean,
    mapView: Boolean,
    mobileMapViewActivated: Boolean
  },
  computed: {
    ...mapGetters(["dataHotelsIsReady"]),
  },
  methods: {
    calculateHotelPrice () {
      return calculateHotelPriceHelper(this.hotel)
    },
    routeToHotelDetail () {
      this.$router.push({
        name: 'hotel-detail-page',
        params: {
          id: this.hotel.id,
          map: this.mobileMapViewActivated
        }
      })
    }
  },
  mounted () {
    /*
      On mobile the clicks on the custom markers are not triggered
      Manually adding this events makes sure you can route the user to the hotel detail page
    */
   setTimeout(() => google.maps.event.addDomListener(this.$el, 'touchend', _e => {
      this.routeToHotelDetail()
    }), 300)

  }
}
</script>

<style lang="scss">
.map-detail {
  background-color: white;
  max-width: 250px;
  min-width: 250px;
  min-height: 115px;
  display: flex;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  :after {
    border-top: 5px solid white;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    bottom: -5px;
    content: "";
    position: absolute;
    left: 58%;
    margin-left: -21px;
    width: 0;
    height: 0;
  }

  .hotel-image {
    img {
      max-width: 100px;
      height: 100px;
      margin-right: 10px;
    }
  }

  .hotel-info {
    display: flex;
    justify-content: col;
    flex-direction: column;
    align-items: baseline;

    img {
      margin-bottom: 5px;
    }

    h2 {
      margin-top: 0;
      font-size: 15px;
      color: $secondary-black;
      flex-grow: 1;
    }

    .hotel-price {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 20px;
      color: $secondary-black;
      letter-spacing: 0.4px;
      font-weight: bold;
    }

    .price-per-night {
      text-transform: uppercase;
      font-size: 8px;
      color: $secondary-black;
      letter-spacing: 0.67px;
    }
  }
}

.inMap {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.13);
}

.mapView {
  border-bottom: 1px solid #E9E9E9;
  border-radius: 0;
  padding: 10px 0;
  max-width: 90%;
}
</style>
