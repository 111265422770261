<template>
  <div class="zoom">
    <a class="btn-zoom" v-on:click="$emit('zoomIn')"><img :src="require('../../../assets/maps/bt_map_zoomin_default.svg')" alt=""></a>
    <a class="btn-zoom" v-on:click="$emit('zoomOut')"><img :src="require('../../../assets/maps/bt_map_zoomout_default.svg')" alt=""></a>
  </div>
</template>

<script>
export default {
  name: 'HotelCustomZoom',
  props: {
    zoom: Number
  }
}
</script>

<style lang="scss">

.zoom {
  position: absolute;
  bottom: 20px;
  right: 20px;

  display: flex;
  flex-direction: column;

  .btn-zoom {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.13);
    background-color: #ffffff;
    width: 50px;
    height: 45px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
  }

}

</style>
