<template>
  <a @click="$emit('map-view-activated')" class="map-view-toggle" v-bind:class="[mapViewActivated ? 'map-view-toggle-position' : 'list-view-toggle-position']">
    <img :src="require('../../../assets/maps/bt_map_full_screen_default.svg')" alt="">
    <span v-if="mapViewActivated">List View</span>
    <span v-else>Map View</span>
  </a>
</template>

<script>
export default {
  name: 'HotelViewToggle',
  props: {
    mapViewActivated: Boolean
  }
}
</script>

<style lang="scss">
.map-view-toggle {
  position: absolute;
  top: 20px;
  background-color: #ffffff;
  width: 140px;
  height: 45px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

  color: $secondary-black;
  text-transform: uppercase;
  font-weight: bold;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.list-view-toggle-position {
  left: 20px;
}

.map-view-toggle-position {
  right: 20px;
}
</style>
